@use 'assets/styles/utils/helper' as h;
@use './theme.tokens.scss';

.container {
  background: var(--event-day-topic-bg-color);
  z-index: h.get-z-index('marquee');
  position: sticky;
  top: 0;
  overflow: hidden;

  .topBar {
    position: relative;
    background: var(--event-day-bg-color);
    max-width: 1310px;
    margin: 0 auto;

    &::after {
      content: '';
      width: 100%;
      height: 48px;
      background: var(--event-day-bg-color);
      position: absolute;
      right: -100%;
      top: 0;
    }
  }

  .scrollContainer {
    display: flex;
    height: 48px;
    align-items: center;
    min-height: min-content;
    -ms-overflow-style: none;
    overflow-x: auto;
    overflow-y: hidden;
    position: relative;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &.hideHeader ~ :global(.layout-header) {
    transform: translateY(-100px);

    @include h.breakpoint-m {
      transform: translateY(0);
    }
  }

  &.hideMobilenav ~ :global(.layout-header) > :global(.hfsh) > :global(.mobilenav) {
    display: none;
  }
}

.leftScroll,
.rightScroll {
  position: absolute;
  z-index: 1;
  width: 0;
  height: 48px;
  color: var(--event-day-chevron-color);
  border: none;
  top: 0;

  > span {
    display: none;
  }

  @include h.breakpoint-m {
    width: 68px;

    > span {
      display: block;
    }
  }
}

.hide {
  display: none;
}

.leftScroll {
  left: 0;
  padding-right: 20px;
  background:
    linear-gradient(
      90deg,
      var(--event-day-scroll-btn-fade-color),
      var(--event-day-scroll-btn-fade-color),
      var(--event-day-scroll-btn-fade-color),
      transparent
    );
}

.rightScroll {
  right: 0;
  padding-left: 20px;
  background:
    linear-gradient(
      90deg,
      transparent,
      var(--event-day-scroll-btn-fade-color),
      var(--event-day-scroll-btn-fade-color),
      var(--event-day-scroll-btn-fade-color)
    );
}

.link {
  padding: 0 16px;
  white-space: nowrap;
  display: flex;
  height: 100%;
  align-items: center;
  position: relative;
  color: var(--event-day-link-color);
  font-family: var(--event-day-font-family);
  font-weight: var(--event-day-font-weight);
  font-size: var(--event-day-font-size);
  line-height: 100%;

  &:hover {
    text-decoration: underline;
    text-decoration-color: var(--event-day-link-hover-underline-color);
    text-decoration-line: underline;
    text-underline-offset: 4px;
    text-decoration-thickness: 2px;
    color: var(--event-day-link-hover-color);
  }

  &:active {
    text-decoration: underline;
    text-decoration-color: var(--event-day-link-hover-underline-color);
    color: var(--event-day-link-hover-color);
    text-decoration-line: underline;
    text-underline-offset: 4px;
    text-decoration-thickness: 2px;
    opacity: 0.7;
  }

  &.current {
    text-decoration: underline;
    text-decoration-color: var(--event-day-link-current-underline-color);
    text-decoration-line: underline;
    text-underline-offset: 4px;
    text-decoration-thickness: 2px;
  }

  &::after {
    content: '';
    border-right: 1px solid var(--event-day-link-separator-color);
    height: 16px;
    position: absolute;
    right: 0;
  }

  &:last-of-type {
    &::after {
      border-right: none;
    }
  }
}

.topic {
  color: var(--event-day-topic-color);
  background: var(--event-day-topic-bg-color);
  font-family: var(--event-day-topic-font-family);
  font-weight: var(--event-day-topic-font-weight);
  font-size: var(--event-day-topic-font-size);
  padding: 0 16px;
  line-height: 100%;
  position: relative;
  flex-shrink: 0;

  @include h.breakpoint-m {
    padding: 0 16px 0 22px;
  }

  .linkText {
    /* stylelint-disable-next-line */
    display: -webkit-box;
    border-bottom: 2px solid transparent;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    max-width: 110px;
    overflow: hidden;
    padding: 4px 0 0 0;
    white-space: normal;

    @include h.breakpoint-m {
      white-space: nowrap;
      max-width: none;
    }
  }

  &:hover {
    color: var(--event-day-topic-link-hover-color);
    text-decoration: none;
    cursor: pointer;

    .linkText {
      border-bottom: 2px solid var(--event-day-topic-hover-underline-color);
      opacity: 1;
    }
  }

  &:active {
    color: var(--event-day-topic-link-active-color);
    text-decoration: none;
    opacity: 1;

    .linkText {
      border-bottom: 2px solid var(--event-day-topic-active-underline-color);
      opacity: 0.5;
    }
  }

  &.current {
    text-decoration: none;

    .linkText {
      border-bottom: 2px solid var(--event-day-topic-current-underline-color);
    }

    &:hover {
      color: var(--event-day-topic-link-hover-color);
      text-decoration: none;

      .linkText {
        border-bottom: 2px solid var(--event-day-topic-hover-underline-color);
        opacity: 0.7;
      }
    }

    &:active {
      color: var(--event-day-topic-link-active-color);
      text-decoration: none;
      opacity: 1;

      .linkText {
        &:active {
          border-bottom: 2px solid var(--event-day-topic-active-underline-color);
          opacity: 0.5;
        }
      }
    }
  }

  :global(body.today) & {
    padding-right: 6px;
    margin-right: 10px;
  }

  &::after {
    content: var(--event-day-topic-bg-ascent);
    position: absolute;
    right: -20px;
    width: 20px;
    height: 48px;
    background: var(--event-day-topic-bg-color);
    clip-path: polygon(10% 0, 90% 100%, 0 100%, 0 0);
  }
}
