@use 'assets/styles/utils/helper' as h;

.container {
  :global(body.today) & {
    --event-day-link-separator-color: #ffb186;
    --event-day-topic-bg-color: #7c4e9f;
    --event-day-topic-bg-ascent: ' ';
    --event-day-link-color: #2a2a2a;
    --event-day-link-hover-color: var(--orange-30);
    --event-day-topic-color: var(--white);
    --event-day-topic-link-hover-color: #fffad1;
    --event-day-topic-link-active-color: #fff38b;
    --event-day-link-hover-underline-color: #ffb186;
    --event-day-link-active-underline-color: #ffb186;
    --event-day-link-current-underline-color: #ffb186;
    --event-day-topic-current-underline-color: var(--white);
    --event-day-topic-hover-underline-color: #ffb186;
    --event-day-topic-active-underline-color: #ffb186;
    --event-day-chevron-color: #7c4e9f;
    --event-day-bg-color: #fff8f4;
    --event-day-font-family: var(--tertiary-font);
    --event-day-topic-font-family: var(--secondary-font);
    --event-day-font-weight: var(--normal);
    --event-day-topic-font-weight: var(--bold);
    --event-day-font-size: 16px;
    --event-day-topic-font-size: 14px;
    --event-day-scroll-btn-fade-color: #fff8f4;

    @include h.breakpoint-m {
      --event-day-font-size: 16px;
      --event-day-topic-font-size: 16px;
    }
  }

  :global(body.select) & {
    --event-day-link-separator-color: var(--blue-60);
    --event-day-topic-bg-color: var(--blue-60);
    --event-day-topic-bg-ascent: none;
    --event-day-chevron-color: var(--blue-60);
    --event-day-link-color: #000;
    --event-day-link-hover-color: var(--blue-60);
    --event-day-topic-color: var(--white);
    --event-day-topic-link-hover-color: rgba(255, 255, 255, 0.7);
    --event-day-topic-link-active-color: rgba(255, 255, 255, 0.5);
    --event-day-link-hover-underline-color: var(--blue-60);
    --event-day-link-active-underline-color: var(--blue-60);
    --event-day-link-current-underline-color: var(--blue-60);
    --event-day-topic-current-underline-color: var(--white);
    --event-day-topic-hover-underline-color: rgba(255, 255, 255, 0.7);
    --event-day-topic-active-underline-color: rgba(255, 255, 255, 0.5);
    --event-day-bg-color: #d7edff;
    --event-day-font-family: var(--lato);
    --event-day-topic-font-family: var(--lato);
    --event-day-font-weight: var(--normal);
    --event-day-topic-font-weight: var(--bold);
    --event-day-font-size: 14px;
    --event-day-topic-font-size: 14px;
    --event-day-scroll-btn-fade-color: #d7edff;

    @include h.breakpoint-m {
      --event-day-font-size: 14px;
      --event-day-topic-font-size: 15px;
    }
  }
}
