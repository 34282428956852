@use 'assets/styles/utils/helper' as h;
@use './theme.tokens' as t;

@mixin zone-a-margin {
  @include h.breakpoint-m {
    &.zone-a-margin {
      margin-top: h.rem(48px);
    }

    // Leaving package types camelCased
    &.zone-a-margin.lead-type--bigStoryTakeover,
    &.zone-a-margin.lead-type--bigStoryTakeoverPlus2,
    &.zone-a-margin.lead-type--bigStoryTakeoverPlus3,
    &.zone-a-margin.lead-type--bigStoryTakeoverPlus4,
    &.zone-a-margin.lead-type--bigHeadline,
    &.zone-a-margin.lead-type--coverSpread {
      margin-top: 0;
    }
  }
}

// Smooth scrolling for preview
body {
  scroll-behavior: smooth;
}

// allow eyebrows to supercede teases
article > div > h2 {
  z-index: 2;
}

.layout-container {
  // Intentionally camel-cased
  .componentHighlight {
    position: relative;

    // Intentionally camel-cased
    & > span.curatorOverlay {
      content: '';
      background-color: h.$curator-yellow;
      z-index: 200;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.4;
    }
  }

  &.flat-layout > div:not([class]) > * {
    @include h.package-spacing;
  }

  body.news & {
    &.zone-a-margin {
      padding-top: 20px;
    }

    &.first-package-cover {
      padding-top: 0;
    }
  }

  body.msnbc & {
    &.zone-a-margin {
      @include h.breakpoint-s-only {
        padding-top: 20px;
      }
    }
  }

  body.msnbc &,
  body.noticias &,
  body.deportes & {
    @include zone-a-margin;
  }

  body.noticias & {
    @include h.breakpoint-m {
      &.zone-a-margin.lead-type--embed {
        margin-top: 0;
      }
    }
  }
}

body.go-see-a-star-war {
  height: 100vh;

  .layout-container {
    transform: perspective(250px) rotateX(29deg) translateY(11%);
    transform-origin: center 500px !important;
    animation: s-w 300s linear 0s 1 normal forwards;
    will-change: transform;
  }
}

@keyframes s-w {
  from {
    transform: perspective(250px) rotateX(29deg) translateY(11%);
  }

  to {
    transform: perspective(250px) rotateX(29deg) translateY(-420%);
  }
}

.header-package {
  margin-top: var(--page-front--header--margin);
  position: relative;
}

.pkg {
  @each $heading in (h1, h2, h3, h4, h5, h6) {
    #{$heading} > a,
    a > #{heading} {
      transition: opacity 0.25s ease-in-out;
    }

    #{$heading} > a:visited,
    a:visited > #{heading} {
      opacity: 1;
    }

    #{$heading} > a:hover,
    a:hover > #{heading} {
      opacity: var(--page-front--hover--opacity);
    }

    #{$heading} > a:active,
    a:active > #{heading} {
      opacity: var(--page-front--active--opacity);
    }
  }
}
