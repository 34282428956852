@use 'assets/styles/utils/helper' as h;

:root {
  --right-rail--left--margin-top: 0;
}

:global(body.news .hasTitle),
:global(body.think .hasTitle),
:global(body.mach .hasTitle),
:global(body.better .hasTitle),
:global(body.msnbc .hasTitle) {
  @include h.breakpoint-l {
    --right-rail--left--margin-top: #{h.rem(60px)};
  }

  @include h.breakpoint-x {
    --right-rail--left--margin-top: #{h.rem(50px)};
  }
}

:global(body.today .hasTitle) {
  @include h.breakpoint-l {
    --right-rail--left--margin-top: #{h.rem(40px)};
  }
}
