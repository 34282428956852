@use 'assets/styles/utils/helper' as h;
@use './theme.tokens' as t;

.left {
  position: relative;

  .sub & {
    order: 2;
  }

  @include h.breakpoint-l {
    .sub & {
      order: 1;
    }
  }
  margin-top: var(--right-rail--left--margin-top);

  & > * {
    @include h.package-spacing;
  }

  &::before {
    :global(.news) & {
      @include h.breakpoint-m {
        content: '';
        width: h.rem(40px);
        height: 100%;
        position: absolute;
        top: 0;
        left: h.rem(-40px);
      }

      @include h.breakpoint-l {
        width: calc((100vw - 920px) / 2);
        left: calc((100vw - 920px) / -2);
      }

      @include h.breakpoint-x {
        width: calc((100vw - 1160px) / 2);
        left: calc((100vw - 1160px) / -2);
      }
    }
  }

  :global(body.today) & {
    @include h.breakpoint-m {
      border-left-width: 0;
    }
  }
}

.right {
  .sub & {
    order: 1;
  }

  @include h.breakpoint-l {
    .sub & {
      order: 2;
    }
  }

  :global(body.today) & {
    @include h.breakpoint-m {
      border-right-width: 0;
    }
  }
}
