@use 'assets/styles/utils/helper' as h;

.layout-container {
  --page-front--header--margin: #{h.rem(-60px)};
  --page-front--hover--opacity: 0.7;
  --page-front--active--opacity: 0.5;

  @include h.breakpoint-m {
    --page-front--header--margin: #{h.rem(-80px)};
  }

  body.news &,
  body.msnbc & {
    --page-front--header--margin: #{h.rem(-60px)};
  }

  body.today & {
    --page-front--hover--opacity: 1;
    --page-front--active--opacity: 1;
    --page-front--header--margin: 0;
  }
}

body.select .lead-type--waffle {
  --page-front--header--margin: 0;
}
