@use 'assets/styles/utils/helper' as h;

.layout-rightRailTabbed {
  --right-rail-tabbed-tab-background-color: var(--default-color);
  --right-rail-tabbed-tab-font-family: var(--default-headline-font-family);
  --right-rail-tabbed-inline-ad-background-color: var(--grey-30);
  --right-rail-tabbed-title-or-pancake-top-margin: #{h.rem(24px)};

  @include h.breakpoint-m {
    --right-rail-tabbed-title-or-pancake-top-margin: #{h.rem(48px)};
  }

  @include h.breakpoint-l {
    --right-rail-tabbed-title-or-pancake-top-margin: 0;
  }
}
