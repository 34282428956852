@use 'assets/styles/utils/helper' as h;

.leftRailWithSplitRow {
  @include h.package-spacing;
}

.main {
  @include h.package-collection-title;
}

.splitCol {
  &:nth-of-type(2n) {
    margin-bottom: 0;
  }

  @include h.breakpoint-m {
    margin-bottom: 0;
  }

  @include h.breakpoint-l {
    &:nth-of-type(2n) {
      margin-bottom: 0;
    }
  }

  @include h.breakpoint-x {
    margin-bottom: 0;
  }
}

.split {
  & .splitCol {
    @include h.package-spacing;
  }
}
