@use 'assets/styles/utils/helper' as h;

.error-message {
  --error-message-headline-color: var(--blue-70);
  --error-message-headline-font-weight: 400;
  --error-message-headline-font-family: var(--founders-cond);
  --error-message-text-brand-color: var(--default-color);
  --error-message-text-brand-font-weight: normal;
  --error-message-text-line-height: var(--leading-150);
  --error-message-text-font-family: var(--publico-txt);
  --error-message-text-color: var(--grey-70);
  --error-message-headline-pattern-background-image: radial-gradient(var(--error-message-text-brand-color) 1px, #0000 0);
  --error-message-headline-pattern-background-position: 1px 1px;
  --error-message-headline-pattern-background-size: 5px 5px;
  --error-message-padding-top: #{h.rem(120px)};
  --error-message-padding-bottom: #{h.rem(116px)};
  --error-message-headline-font-size: #{h.rem(28px)};
  --error-message-headline-container-margin-bottom: #{h.rem(32px)};
  --error-message-headline-pattern-width: #{h.rem(127px)};
  --error-message-headline-pattern-height: #{h.rem(17px)};
  --error-message-headline-pattern-bottom: #{h.rem(-3px)};
  --error-message-headline-pattern-right: #{h.rem(-22px)};
  --error-message-headline-margin-bottom: #{h.rem(32px)};
  --error-message-text-font-size: #{h.rem(14px)};

  @include h.breakpoint-m {
    --error-message-padding-top: #{h.rem(160px)};
    --error-message-padding-bottom: #{h.rem(204px)};
    --error-message-headline-font-size: #{h.rem(60px)};
    --error-message-headline-container-margin-bottom: #{h.rem(48px)};
    --error-message-headline-pattern-width: #{h.rem(332px)};
    --error-message-headline-pattern-height: #{h.rem(52px)};
    --error-message-headline-pattern-bottom: #{h.rem(-7px)};
    --error-message-headline-pattern-right: #{h.rem(-20px)};
    --error-message-headline-margin-bottom: #{h.rem(96px)};
    --error-message-text-font-size: #{h.rem(20px)};
  }

  @include h.breakpoint-l {
    --error-message-padding-top: #{h.rem(184px)};
    --error-message-padding-bottom: #{h.rem(230px)};
    --error-message-headline-font-size: #{h.rem(80px)};
    --error-message-headline-container-margin-bottom: #{h.rem(56px)};
    --error-message-text-font-size: #{h.rem(24px)};
  }

  @include h.breakpoint-x {
    --error-message-padding-bottom: #{h.rem(246px)};
    --error-message-headline-font-size: #{h.rem(96px)};
  }

  body.news & {
    --error-message-headline-pattern-background-image: radial-gradient(var(--blue-70) 1px, #0000 0);
  }

  body.select & {
    --error-message-headline-color: var(--grey-70);
    --error-message-headline-pattern-background-image: url("data:image/svg+xml,%3Csvg width='118' height='118' viewBox='0 0 118 118' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg opacity='0.5' clip-path='url(%23clip0)'%3E%3Crect x='124.927' y='-156.917' width='8' height='281.889' transform='rotate(35 124.927 -156.917)' fill='%2374C5FF'/%3E%3Crect x='127.927' y='-188.917' width='8' height='281.889' transform='rotate(35 127.927 -188.917)' fill='%2374C5FF'/%3E%3Crect x='148.685' y='-135' width='8' height='281.889' transform='rotate(35 148.685 -135)' fill='%2374C5FF'/%3E%3Crect x='153.685' y='-114' width='8' height='281.889' transform='rotate(35 153.685 -114)' fill='%2374C5FF'/%3E%3Crect x='129.685' y='-135' width='8' height='281.889' transform='rotate(35 129.685 -135)' fill='%2374C5FF'/%3E%3Crect x='219.685' y='-68' width='8' height='281.889' transform='rotate(35 219.685 -68)' fill='%2374C5FF'/%3E%3Crect x='222.685' y='-44' width='10' height='281.889' transform='rotate(35 222.685 -44)' fill='%2374C5FF'/%3E%3Crect x='200.685' y='-68' width='8' height='281.889' transform='rotate(35 200.685 -68)' fill='%2374C5FF'/%3E%3Crect x='208.685' y='-108' width='8' height='281.889' transform='rotate(35 208.685 -108)' fill='%2374C5FF'/%3E%3Crect x='168.685' y='-108' width='8' height='281.889' transform='rotate(35 168.685 -108)' fill='%2374C5FF'/%3E%3Crect x='190.685' y='-110' width='8' height='281.889' transform='rotate(35 190.685 -110)' fill='%2374C5FF'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0'%3E%3Crect width='118' height='118' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
    --error-message-headline-pattern-background-position: unset;
    --error-message-headline-pattern-background-size: unset;
    --error-message-headline-font-family: 'Arial';
    --error-message-text-font-family: 'Arial';
    --error-message-headline-font-size: #{h.rem(24px)};

    @include h.breakpoint-m {
      --error-message-headline-font-size: #{h.rem(60px)};
    }

    @include h.breakpoint-l {
      --error-message-headline-font-size: #{h.rem(60px)};
    }

    @include h.breakpoint-x {
      --error-message-headline-font-size: #{h.rem(80px)};
    }
  }

  body.telemundo & {
    --error-message-headline-color: #0f0f19;
    --error-message-headline-pattern-background-image: url("data:image/svg+xml,%3Csvg width='118' height='118' viewBox='0 0 118 118' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg opacity='0.5' clip-path='url(%23clip0)'%3E%3Crect x='124.927' y='-156.917' width='8' height='281.889' transform='rotate(35 124.927 -156.917)' fill='%23C3C8CD'/%3E%3Crect x='127.927' y='-188.917' width='8' height='281.889' transform='rotate(35 127.927 -188.917)' fill='%23C3C8CD'/%3E%3Crect x='148.685' y='-135' width='8' height='281.889' transform='rotate(35 148.685 -135)' fill='%23C3C8CD'/%3E%3Crect x='153.685' y='-114' width='8' height='281.889' transform='rotate(35 153.685 -114)' fill='%23C3C8CD'/%3E%3Crect x='129.685' y='-135' width='8' height='281.889' transform='rotate(35 129.685 -135)' fill='%23C3C8CD'/%3E%3Crect x='219.685' y='-68' width='8' height='281.889' transform='rotate(35 219.685 -68)' fill='%23C3C8CD'/%3E%3Crect x='222.685' y='-44' width='10' height='281.889' transform='rotate(35 222.685 -44)' fill='%23C3C8CD'/%3E%3Crect x='200.685' y='-68' width='8' height='281.889' transform='rotate(35 200.685 -68)' fill='%23C3C8CD'/%3E%3Crect x='208.685' y='-108' width='8' height='281.889' transform='rotate(35 208.685 -108)' fill='%23C3C8CD'/%3E%3Crect x='168.685' y='-108' width='8' height='281.889' transform='rotate(35 168.685 -108)' fill='%23C3C8CD'/%3E%3Crect x='190.685' y='-110' width='8' height='281.889' transform='rotate(35 190.685 -110)' fill='%23C3C8CD'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0'%3E%3Crect width='118' height='118' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
    --error-message-headline-pattern-background-position: unset;
    --error-message-headline-pattern-background-size: unset;
    --error-message-headline-font-size: #{h.rem(28px)};

    @include h.breakpoint-m {
      --error-message-headline-font-size: #{h.rem(60px)};
    }

    @include h.breakpoint-l {
      --error-message-headline-font-size: #{h.rem(80px)};
    }

    @include h.breakpoint-x {
      --error-message-headline-font-size: #{h.rem(96px)};
    }
  }

  body.think & {
    --error-message-headline-color: var(--grey-70);
    --error-message-headline-font-family: var(--publico-hed);
    --error-message-headline-font-size: #{h.rem(24px)};

    @include h.breakpoint-m {
      --error-message-headline-font-size: #{h.rem(60px)};
    }

    @include h.breakpoint-l {
      --error-message-headline-font-size: #{h.rem(80px)};
    }
  }

  body.today & {
    --error-message-headline-font-family: var(--primary-font);
    --error-message-headline-font-weight: normal;
    --error-message-headline-color: var(--grey-70);
    --error-message-headline-font-size: #{h.rem(32px)};
    --error-message-text-font-family: var(--secondary-font);
    --error-message-text-font-size: h.rem(20px);
    --error-message-headline-container-margin-bottom: #{h.rem(20px)};
    --error-message-text-brand-color: var(--grey-70);

    @include h.breakpoint-m {
      --error-message-headline-font-size: #{h.rem(48px)};
      --error-message-text-font-size: #{h.rem(24px)};
      --error-message-headline-container-margin-bottom: #{h.rem(30px)};
    }

    @include h.breakpoint-l {
      --error-message-headline-font-size: #{h.rem(60px)};
    }

    @include h.breakpoint-x {
      --error-message-headline-font-size: #{h.rem(80px)};
    }
  }

  body.better & {
    --error-message-headline-color: var(--purple-70);
    --error-message-headline-font-size: #{h.rem(28px)};

    @include h.breakpoint-m {
      --error-message-headline-font-size: #{h.rem(60px)};
    }

    @include h.breakpoint-l {
      --error-message-headline-font-size: #{h.rem(80px)};
    }

    @include h.breakpoint-x {
      --error-message-headline-font-size: #{h.rem(96px)};
    }
  }
}
