@use 'assets/styles/utils/helper' as h;
@use './theme.tokens' as t;

.error-message {
  padding-top: var(--error-message-padding-top);
  padding-bottom: var(--error-message-padding-bottom);
  position: relative;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  &__headline {
    font-size: var(--error-message-headline-font-size);
    margin: 0;
    color: var(--error-message-headline-color);
    font-family: var(--error-message-headline-font-family);
    font-weight: var(--error-message-headline-font-weight);
    line-height: 1;
  }

  &__headline-container {
    margin-bottom: var(--error-message-headline-container-margin-bottom);
    position: relative;
  }

  &__headline-pattern {
    width: var(--error-message-headline-pattern-width);
    height: var(--error-message-headline-pattern-height);
    bottom: var(--error-message-headline-pattern-bottom);
    right: var(--error-message-headline-pattern-right);
    background-image: var(--error-message-headline-pattern-background-image);
    background-position: var(--error-message-headline-pattern-background-position);
    background-size: var(--error-message-headline-pattern-background-size);
    position: absolute;
    z-index: -1;
  }

  &__text-container {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
  }

  &__text {
    font-size: var(--error-message-text-font-size);
    margin: 0;
    font-family: var(--error-message-text-font-family);
    line-height: var(--error-message-text-line-height);
    width: 100%;
    position: relative;
    z-index: 1;
    color: var(--error-message-text-color);
    max-width: 50%;

    &--branded {
      font-weight: var(--error-message-text-brand-font-weight);
      color: var(--error-message-text-brand-color);
    }

    a {
      &:hover {
        opacity: var(--default-hover-opacity);
      }

      &:active {
        opacity: var(--default-active-opacity);
      }

      body.today & {
        text-decoration-line: underline;
        text-decoration-color: var(--accent-orange);
        text-decoration-thickness: 2px;
        text-underline-offset: 3px;

        &:hover {
          color: var(--primary-color);
        }
      }
    }
  }

  body.today & {
    @include h.slanted-background;

    &::before {
      height: h.rem(150px);
      top: calc(50%);
      transform: translateX(-50%) translateY(-50%) rotate(2.3deg) skew(2.3deg);

      @include h.breakpoint-m {
        height: h.rem(200px);
        top: calc(50% - 30px);
      }
    }
  }
}
