@use 'assets/styles/utils/helper' as h;
@use './theme.tokens' as t;

.right-rail-tabbed__tabs {
  display: none;
  position: sticky;
  top: var(--navbar-mobile-height);
  z-index: 18;

  @include h.breakpoint-m {
    top: var(--navbar-default-height);
  }

  &--visible {
    display: flex;

    @include h.breakpoint-l {
      display: none;
    }
  }
}

.layout-rightRailTabbed.small {
  .right-rail-tabbed__tabs {
    @include h.breakpoint-m {
      top: var(--navbar-mobile-height);
    }
  }
}

.right-rail-tabbed__tab {
  font-family: var(--right-rail-tabbed-tab-font-family);
  font-size: h.rem(17px);
  line-height: h.rem(50px);
  text-transform: uppercase;
  background-color: var(--right-rail-tabbed-tab-background-color);
  flex: 0 0 50%;
  height: h.rem(50px);
  color: rgba(var(--white-rgb), .4);
  text-align: center;
  cursor: pointer;

  @include h.breakpoint-m {
    height: h.rem(70px);
    font-size: h.rem(20px);
    line-height: h.rem(70px);
  }

  &:first-child {
    border-right: 1px solid rgba(var(--white-rgb), .2);
  }

  &--active {
    color: rgba(var(--white-rgb), 1);
  }
}

.right-rail-tabbed__container--left {
  position: relative;

  @include h.breakpoint-m {
    margin-bottom: h.rem(48px);
  }
}

.right-rail-tabbed__has-title-is-pancake {
  margin-top: var(--right-rail-tabbed-title-or-pancake-top-margin);
}
